import { render, staticRenderFns } from "./demandReport.vue?vue&type=template&id=0f63cf33&scoped=true&"
import script from "./demandReport.vue?vue&type=script&lang=js&"
export * from "./demandReport.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f63cf33",
  null
  
)

export default component.exports