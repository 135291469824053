<template>
  <div class="view pa24">
    选择状态：
    <el-radio
      class="mr10"
      style="margin: 0"
      v-for="(item, index) in radioArr"
      :key="index"
      v-model="radioValue"
      :label="item.value"
      border
      >{{ item.text }}</el-radio
    >
    <el-button type="primary" @click="searchFun">搜索</el-button>
    <commonTable
      :tableData="tableData"
      :loading="loading"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :currentPage="currentPage"
      :total="total"
    >
      <template v-slot:table>
        <el-table-column
          type="index"
          align="center"
          label="序号"
          show-overflow-tooltip
        />
        <el-table-column prop="reportingTime" align="center" label="举报时间">
          <template slot-scope="scope">
            {{ scope.row.reportingTime | getDataTimeSec }}
          </template>
        </el-table-column>
        <el-table-column prop="companyName" align="center" label="公司名称" />
        <el-table-column prop="name" align="center" label="发布人" />
        <el-table-column prop="description" align="center" label="内容" show-overflow-tooltip/>
        <el-table-column align="center" prop="images" label="图片">
          <template slot-scope="scope">
            <el-image
              style="width: 36px; height: 36px"
              :src="scope.row.imagesList[0]"
              :preview-src-list="scope.row.imagesList"
            >
            </el-image>
            <span>x{{ scope.row.imagesList.length }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="note" label="备注" show-overflow-tooltip/>
        <el-table-column align="center" prop="reporting" label="举报原因" show-overflow-tooltip/>
        <el-table-column align="center" fixed="right" label="操作" width="170">
          <template slot-scope="scope">
            <customPopconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              @confirm="offShelf(scope.row)"
              v-if="scope.row.status == 1"
              icon="el-icon-info"
              icon-color="red"
              title="确认强制下架吗？">
                <el-button type="text" slot="reference" size="small">
                  强制下架
                </el-button>
            </customPopconfirm>
            <span v-else>已下架</span>
          </template>
        </el-table-column>
      </template>
    </commonTable>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import customPopconfirm from "@/components/common/customPopconfirm";
import { getDataTimeSec } from "@/utils";
import { queryReport, updateDemandSquareStatus } from "@/api/addedServices";
export default {
  data() {
    return {
      radioValue: "",
      radioArr: [
        { text: "全部", value: "" },
        { text: "已下架", value: "0" },
        { text: "已上架", value: "1" },
      ],
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
    };
  },
  components: {
    commonTable,
    customPopconfirm
  },
  filters: {
    getDataTimeSec(val) {
      return getDataTimeSec(val);
    },
  },
  created() {
    this.queryReport();
  },
  methods: {
    searchFun(){
      this.currentPage=1;
      this.queryReport();
    },
    handleSizeChange(val) {
      this.currentPage =1;
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    async queryReport() {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        status: this.radioValue,
      };
      try {
        this.loading = true;
        const result = await queryReport(data);
        this.loading = false;
        const {
          pageInfo: { total, list },
        } = result?.data;
        list.forEach((item) => {
          item.imagesList = JSON.parse(item.images);
        });
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.loading = false;
        //-console.log(error);
      }
    },
    async offShelf(row) {
      let data = JSON.parse(JSON.stringify(row));
      data.status = false;
      await updateDemandSquareStatus(data);
      this.queryReport();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>